.chats .chat:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: 0;
  border-bottom: 30px solid white;
}

.chats .left-arrow:after {
  left: 30px;
  border-left: 30px solid transparent;
}

.chats .right-arrow:after {
  right: 30px;
  border-right: 30px solid transparent;
}

.time-container {
  text-align: center;
  border-radius: 30%;
  margin: auto;
}

.title {
  font-size: 18px;
  margin: 10px;
  color: #0958d9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  border-radius: 4%;
  padding: 8px;
  margin: 0 5px;
  background-color: #f0f0f0;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.digit {
  font-size: 24px;
  border-radius: 30%;
  padding: 8px;
  margin: 0 5px;
  background-color: #f0f0f0;
  color: #0958d9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timer-expired {
  color: gray;
}

.title-red {
  color: red;
}

.digit-red {
  color: red;
}