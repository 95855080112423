@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  background-color: #f0f0f0;
}

/* Firefox */
.code-output .ant-card-body,
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #f0f0f0 #bfbfbf;
}

/* Chrome, Edge, and Safari */
.code-output .ant-card-body::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.code-output .ant-card-body::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.code-output .ant-card-body::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 14px;
  border: 3px solid #bfbfbf;
}

.ant-alert-message p:first-child {
  margin: 0;
}

.ant-alert-message pre {
  white-space: pre-wrap;
  background: black;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.login-form label,
.signup-form label {
  height: auto !important;
}

.ant-menu-item:hover {
  background-color: rgba(51, 51, 51, 0.5) !important;
  color: #4893DB !important;
  border-right:3px solid #4893DB;
}


.ant-layout-sider {
  position: sticky !important;
}

.ant-modal-content {
  border-radius: 16px !important;
  padding: 16px !important;
}

.ant-form-item-label,
.ant-form-item-explain,
.ant-input {
  font-family: "Poppins" !important;
}

.ant-layout {
  background-color: #ffffff;
}

.ant-select-selection-item,
.ant-select-item-option-content {
  font-family: "Poppins" !important;
  color: #696969 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: 0.14px !important;
}

.ant-select-item-option-selected .ant-select-item-option-content {
  font-family: "Poppins" !important;
  color: #696969 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0.14px !important;
}

.ant-select-item {
  padding: 12px 16px !important;
}

.ant-tooltip-inner {
  font-family: "Poppins" !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

.ant-menu-item {
  display: flex !important;
  color: #ffffff !important;
  align-items: center;
}

.ant-progress-line {
  margin: 0px !important;
}

.ant-menu-item-selected {
  background-color: rgba(51, 51, 51, 0.5) !important;
  color: #4893DB !important;
  border-right:3px solid #4893DB;
}
